* {
  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }

  &:active,
  :focus {
    outline: none !important;
  }
}

label {
  margin: 0;
}

html,
body {
  height: 100%;
  font-family: 'Roboto Slab', 'Open Sans', 'sans-serif';
  // font-family: 'Lato', 'Open Sans', 'sans-serif';
}

code {
  white-space: nowrap;
  border-radius: 10px;
  padding: 0 8px 1px 8px;
}
